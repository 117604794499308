<script setup>
import storage from '@/assets/storage';
import nav_data from '@/config/nav_data'
import nav_data_en from '@/config/nav_data_en';
import nav_data_hk from '@/config/nav_data_hk';
import { useRouter } from 'vue-router'
import tool from '../assets/tools.js'
const user = useUserStore()
const hasLogin = ref(true)
const { locale } = useI18n()
const open = ref(false)
const open_drawer = ref(false)
const switchLocalePath = useSwitchLocalePath()
const pathname = ref('')
const route = useRoute()
const router = useRouter()
function loginBtn() {
  if (locale.value === 'cn') {
    router.push('/newstatements')

  } else {
    router.push('/hk/newstatements')

  }
}
onMounted(() => {
 if (tool.getCookie('fd_gw_token')) {
   hasLogin.value = false
 }
})

const props = defineProps(['isIp'])
const navList = computed(() => {
  switch (locale.value) {
    case 'cn':
      return !props.isIp? nav_data : nav_data.filter(item => item.name !== 'download');
      break;
    case 'en':
      return nav_data_en;
      break;
    case 'hk':
      return !props.isIp ? nav_data_hk : nav_data_hk.filter(item => item.name !== 'download');
      break;
    default:
      return nav_data;
  }
})

function handleOk() {
  open.value = false
  loginBtn()
}

function onClose() {
  open_drawer.value = false
}
function pathCompare(path) {
  if (!path) return '';

  const keywords = {
    'index': ['index'],
    'stock': ['stock_trade', 'stock', 'fund_product', 'futures', 'insurance_detail'],
    'wealth': ['wealth_planning', 'full_delegation'],
    'global_investment': ['global_investment'],
    'investment': ['investment'],
    'activity': ['activity'],
    'service': ['service'],
    'company': ['company'],
    'download': ['download'],

  };
  for (const [category, patterns] of Object.entries(keywords)) {
    if (patterns.some(pattern => path.includes(pattern))) {
      return category;
    }
  }

  return '';
}
// console.log(100, process.client)
// if (process.client) {
//   console.log(200)
//   pathname.value = location.pathname
// }
const Logout = () => {
  // 退出登陆
  tool.delCookie('fd_gw_token',location.hostname)
  window.localStorage.removeItem('token')
  hasLogin.value = true

  if (locale.value === 'cn') {
    router.push('/')

  } else {
    router.push('/hk')

  }
}
pathname.value = route.path === '/' ? 'index' : route.path
</script>
<template>
  <div sticky top-0 z-100>
    <div pc>
      <div h-48 bg-body text-white f-c>
        <div mwc flex justify-end>
          <ul f-c-b w-200>
            <li cp>
              <!-- <NuxtLink :to="localePath('/contact')" external>{{ $t('contact_us') }}</NuxtLink> -->
            </li>
            <li cp>
              <a-dropdown>
                <span  @click="loginBtn">{{ $t('login') }}</span>
                <template #overlay>
                  <a-menu>
                    <a-menu-item>
                      <span  @click="Logout">{{ $t('logout') }}</span>
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </li>
            <li>
              <a-dropdown>
                <a href="javascript:;" @click.prevent f-c-c cp>
                  {{ locale === 'en' ? 'English' : locale === 'hk' ? '繁' : '简' }} <span i-carbon-caret-down block w-20
                    h-20></span>
                </a>
                <template #overlay>
                  <a-menu>
                    <a-menu-item>
                      <NuxtLink :to="switchLocalePath('cn')">简</NuxtLink>
                    </a-menu-item>
                    <a-menu-item>
                      <NuxtLink :to="switchLocalePath('hk')">繁</NuxtLink>
                    </a-menu-item>
                    <!-- <a-menu-item>
                      <NuxtLink :to="switchLocalePath('en')">English</NuxtLink>
                    </a-menu-item> -->
                  </a-menu>
                </template>
              </a-dropdown>
            </li>
          </ul>
        </div>
      </div>
      <div h-80 bg-white f-c>
        <div mwc f-c-b>
          <div f-c>
            <NuxtLink :to="localePath('/')" external><img h-35  :src="index.cn.logo" cp alt="" srcset=""></NuxtLink>
            <ul f-c-c :class="{ 'ml-50': locale == 'en', 'ml-100': locale != 'en' }">
              <li cp class="text_active group" :class="{
                'mr-20': locale == 'en',
                'mr-40': locale != 'en',
                ' text-accent': pathname && pathCompare(pathname)?.indexOf(item.name) != -1
              }" data-hover="true" hover:text-accent v-for="(item, index) in navList" :key="index">
                <NuxtLink :to="localePath(item.path)" :target="item.target" external v-if="item.path"> {{ item.title }}</NuxtLink>
                <span v-else>{{ item.title }}</span>
                <div v-if="item.child" w-full h-0 transition ease-in-out delay-150 overflow-hidden fixed top-88 left-0
                  group-hover:(h-auto overflow-auto)>
                  <div h-40 bg-transparent class="title-headline"></div>
                  <div bg-white pt-20 pb-20 text-body>
                    <div mwc>
                      <ul f-c items-start pl-200>
                        <li mr-35 v-for="(ele, i) in item.child" :key="i">
                          <NuxtLink :to="localePath(ele.path)" external v-if="ele.path">
                            <p titm mb-20 fw hover:text-accent>{{ ele.title }}</p>
                          </NuxtLink>
                          <p titm mb-20 fw hover:text-accent v-else>{{ ele.title }}</p>
                          <NuxtLink :to="localePath(nav.path)" external v-show="ele.child"
                            v-for="(nav, n) in ele.child">
                            <p titm text-16 mb-10 hover:text-accent>
                              {{ nav.title }}
                            </p>
                          </NuxtLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div>
            <!-- <button btn-accent :class="{
              'mr-20': locale == 'en',
              'mr-40': locale != 'en',
            }">{{ $t('open_account') }}</button> -->
            <!-- <NuxtLink :to="localePath('/download')" external><button btn2-accent>{{ $t('download_center') }}</button>
            </NuxtLink> -->
          </div>
        </div>
      </div>
    </div>
    <div mo w-full f-c-b py-18 px-18 bg-white>
      <NuxtLink :to="localePath('/')" external><img h-28 :src="index.cn.logo" cp alt="" srcset=""></NuxtLink>
      <div i-material-symbols-menu text-24 text-silvergray @click="open_drawer = true"></div>
      <a-drawer :closable="false" width="100vw" height="100vh" placement="top" :open="open_drawer">
        <MBHeader :isIp="props.isIp" @moclose="open_drawer = false" />
      </a-drawer>
    </div>
    <a-modal v-model:open="open" @ok="handleOk" cancelText="取消" okText="确定">
      <p titm>是否退出退出登录</p>
    </a-modal>
  </div>
</template>
<style>
.title-headline::after{
  display: none;

}
</style>
