<script setup>

</script>
<template>
  <div bg-body text-white pb-22 pt-39 xl:f-c lt-xl:(pt-30 pb-26)>
    <div mwc>
      <!-- <div text-center mb-16 text-22 lt-xl:(text-14 mb-28)>{{ $t('provided') }}</div> -->
      <div items-start xl:f-c-b>
        <!-- <div>
          <p mb-17 text-22 lt-xl:(text-18 mb-26)>{{ $t('company_name') }}</p>
          <ul text-16 mb-24 lt-xl:(text-13 mb-34)>
            <li f-c mb-20 lt-xl:mb-17>
              <img w-20 lt-xl:w-16 src="https://imgoss.techfx88.com/go_fd_do/fd-1711333900-rqjrj5.png" alt="" srcset="">
              <p ml-26 lt-xl:ml-21>+852 2957 6000</p>
            </li>
            <li f-c mb-20 lt-xl:mb-17>
              <img w-22 lt-xl:w-18 src="https://imgoss.techfx88.com/go_fd_do/fd-1711334141-2nkgsq.png" alt="" srcset="">
              <p ml-26 lt-xl:ml-19>info@forthright-sec.com</p>
            </li>
            <li f-c mb-20 lt-xl:mb-17>
              <img w-20 lt-xl:w-13 src="https://imgoss.techfx88.com/go_fd_do/fd-1711334167-btydph.png" alt="" srcset="">
              <p ml-26 lt-xl:ml-21>{{ $t('address') }}</p>
            </li>
            <li flex items-start>
              <img w-20 lt-xl:w-13 src="https://imgoss.techfx88.com/www_img/www_upload/default17187910001796.png" alt=""
                srcset="">
              <div ml-26 lt-xl:ml-21>
                <p>{{ $t('Telephone_order') + ':' }}</p>
                <div flex gap-x-37 lt-xl:(flex-col gap-y-13)>
                  <div>
                    <p>400 100 1359</p>
                    <p c-deepGray>{{ $t('1_5') }}</p>
                  </div>
                  <div>
                    <p>+852 2957 6018</p>
                    <p c-deepGray>{{ $t('work_time') }}</p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <ul f-c mb-50 lt-xl:mb-34>
            <li mr-15 lt-xl:mr-12>
              <img w-26 lt-xl:w-21 src="https://imgoss.techfx88.com/go_fd_do/fd-1711334722-e5ggc5.png" alt="" srcset="">
            </li>
            <li mr-15 lt-xl:mr-12>
              <img w-26 lt-xl:w-21 src="https://imgoss.techfx88.com/go_fd_do/fd-1711334754-ekq2kr.png" alt="" srcset="">
            </li>
            <li mr-15 lt-xl:mr-12>
              <img w-26 lt-xl:w-21 src="https://imgoss.techfx88.com/go_fd_do/fd-1711334780-3646l4.png" alt="" srcset="">
            </li>
            <li mr-15 lt-xl:mr-12>
              <img w-26 lt-xl:w-21 src="https://imgoss.techfx88.com/go_fd_do/fd-1711334814-mb0phy.png" alt="" srcset="">
            </li>
            <li mr-15 lt-xl:mr-12>
              <img w-26 lt-xl:w-21 src="https://imgoss.techfx88.com/go_fd_do/fd-1711334838-h10jmr.png" alt="" srcset="">
            </li>
            <li>
              <img w-26 lt-xl:w-21 src="https://imgoss.techfx88.com/go_fd_do/fd-1711334867-61zhcb.png" alt="" srcset="">
            </li>
          </ul>
        </div> -->


        <div  flex justify-center>
          <ul block mb-20>
            <li text-16 mr-220 lt-xl:(text-13 mr-0) >
              <!-- <p text-silvergray text-18 mb-22 lt-xl:(text-14 mb-17) l-n>{{ $t('securities_services') }}</p> -->
              <div flex flex-col justify-between py-5>
                <p l-n lt-xl:(text-14) :style="{color: '#BDC3C5'}"><span mr-16 class="mark-red"></span>{{ $t('qgfwrx') }}</p>
                <p l-n lt-xl:(text-12 mt-24 ml-32) :style="{color: '#ffffff'}">400-100-1359</p>
                <p l-n lt-xl:(text-12 mt-16 ml-32) :style="{color: '#BDC3C5'}">(852)2957-6133(香港）周一至周五9：00~18：00
                <!-- <p mt-6 lt-xl:(text-16 mt-16) :style="{color: '#ffffff'}" class="content-tips" font-size-12></p> -->
                </p>

              </div>
            </li>
            <li text-16 mr-220 lt-xl:(text-13 mr-0 mt-16)>
              <!-- <p text-silvergray text-18 mb-22 lt-xl:(text-14 mb-17) l-n>{{ $t('securities_services') }}</p> -->
              <div flex flex-col  justify-between py-5>
                <p l-n lt-xl:(text-14) :style="{color: '#BDC3C5'}"><span mr-16 class="mark-red"></span>{{ $t('dhxd') }}</p>
                <p l-n lt-xl:(text-12 mt-24 ml-32) :style="{color: '#ffffff'}">400-100-1359   {{ $t('jthxa1za1')  }}</p>
                <p l-n lt-xl:(text-12 mt-16 ml-32) :style="{color: '#BDC3C5'}">
                  (852)2957-6018(香港）周一至周五8：00~00：00
                <!-- <p mt-6 lt-xl:(text-16 mt-16) :style="{color: '#ffffff'}" class="content-tips" font-size-12></p> -->
                </p>

              </div>
            </li>
            <li text-16 mr-220 lt-xl:(text-13 mr-0 mt-16)>
              <!-- <p text-silvergray text-18 mb-22 lt-xl:(text-14 mb-17) l-n>{{ $t('securities_services') }}</p> -->
              <div flex flex-col  justify-between py-5>
                <p l-n lt-xl:(text-14) :style="{color: '#BDC3C5'}"><span mr-16 class="mark-red"></span>{{ $t('dy1') }}</p>
                <p l-n lt-xl:(text-12 mt-24 ml-32) :style="{color: '#ffffff'}">cs@forthright-sec.com</p>
                <p l-n lt-xl:(text-12 mt-16 ml-32) :style="{color: '#BDC3C5'}">
                  {{ $t('dz1')}}  {{ $t('dznr')  }}
                <!-- <p mt-6 lt-xl:(text-16 mt-16) :style="{color: '#ffffff'}" class="content-tips" font-size-12></p> -->
                </p>

              </div>
            </li>
            <li text-16 mr-220 lt-xl:(text-13 mr-0 mt-16)>
              <!-- <p text-silvergray text-18 mb-22 lt-xl:(text-14 mb-17) l-n>{{ $t('securities_services') }}</p> -->
              <div flex flex-col  justify-between py-5>
                <p l-n lt-xl:(text-18) :style="{color: '#BDC3C5'}"><span mr-16 class="mark-red"></span>{{ $t('tksm') }}</p>
                <div flex  mt-24>
                  <NuxtLink :to="localePath('/service_detail?pid=159&id=607')" external>
                    <p l-n  lt-xl:(text-12 ml-32) >{{ $t('mzsmjfxpl') }}</p>
                  </NuxtLink>
                  <NuxtLink :to="localePath('/service_detail?pid=159&id=606')" external>
                   <p l-n  lt-xl:(text-12 ml-24)> {{ $t('gryszc') }}</p>
                 </NuxtLink>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div mt-16 text-14 text-justify text-white text-opacity-39 lt-xl:text-12>
        {{ $t('bwzjsnr') }}
      </div>
      <div mt-16 text-14 text-center text-white text-opacity-39 lt-xl:text-12>  {{ $t('bqsy') }}</div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.content-tips {

  font-family: PingFangSC, PingFang SC;
  font-weight: 400;

  color: #FFFFFF;


  font-style: normal;
  opacity: 0.7;
}
.mark-red {
  display: inline-block;
  width: 16px;
  height: 16px;
background: #C52727;
}
</style>
