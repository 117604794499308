<script setup>
import nav_data from '@/config/nav_data'
import nav_data_en from '@/config/nav_data_en';
import nav_data_hk from '@/config/nav_data_hk';
import tool from '../assets/tools.js'
import { useRouter } from 'vue-router'
const router = useRouter()

const { locale } = useI18n()
const props = defineProps(['isIp'])
const emits = defineEmits(['moclose'])
const switchLocalePath = useSwitchLocalePath()
function navOpen(item) {
  console.log('1111111',item)
  console.log(item);
  if (item.child) {
    if (item.open) {
      item.open = false
    } else {
      item.open = true
    }
  }
  console.log(item);
}
let navList = ref([])
const switchList = () => {
  switch (locale.value) {
    case 'cn':
      navList.value = !props.isIp? nav_data : nav_data.filter(item => item.name !== 'download');
      break;
    case 'en':
      navList.value = nav_data_en;
      break;
    case 'hk':
      navList.value = !props.isIp ? nav_data_hk : nav_data_hk.filter(item => item.name !== 'download');
      break;
    default:
      navList.value = nav_data;
  }
}
switchList()

watch(locale, () => {
  switchList()
})
function closeNav() {
  emits('moclose', true)
}
function loginBtn() {
  if (locale.value === 'cn') {
    router.push('/newstatements')

  } else {
    router.push('/hk/newstatements')

  }
  closeNav()
}
const hasLogin = ref(true)

const Logout = () => {
  // 退出登陆
  tool.delCookie('fd_gw_token',location.hostname)
  window.localStorage.removeItem('token')
  hasLogin.value = true
  if (locale.value === 'cn') {
    router.push('/')

  } else {
    router.push('/hk')

  }
}
onMounted(() => {
 if (tool.getCookie('fd_gw_token')) {
   hasLogin.value = false
 }
})
</script>

<template>
  <div f-col relative pb-80>
    <div mo w-full f-c-b bg-white px-15 py-19 border-solid border-b-1 border-palebluishgray>
      <NuxtLink :to="localePath('/')" external><img h-28 :src="index.cn.logo" cp alt="" srcset=""></NuxtLink>
      <div i-carbon-close-large text-24 text-body @click="closeNav"></div>
    </div>
    <ul>
      <li px-15 border-solid border-b-1 border-palebluishgray py-14 f-col v-for="(item, index) in navList" :key="index">
        <div f-c-b w-full @click="navOpen(item)" v-if="!item.path">
          <p text-16 :class="{ 'text-14!': locale == 'en' }" lh-23>{{ item.title }}</p>
          <p i-material-symbols-chevron-right text-16 :class="{ 'text-14!': locale == 'en' }" text-silvergray
            v-if="!item.child"></p>
          <p i-material-symbols-keyboard-arrow-down text-16 :class="{ 'text-14!': locale == 'en' }" text-silvergray
            v-if="item.child && !item.open"></p>
          <p i-material-symbols-keyboard-arrow-up text-16 :class="{ 'text-14!': locale == 'en' }" text-silvergray
            v-if="item.child && item.open"></p>
        </div>
        <div v-else>
          <NuxtLink f-c-b w-full :to="localePath(item.path)" external>
            <p text-16 :class="{ 'text-12!': locale == 'en' }" lh-23>{{ item.title }}</p>
            <p i-material-symbols-chevron-right text-16 :class="{ 'text-12!': locale == 'en' }" text-silvergray></p>
          </NuxtLink>
        </div>
        <ul pt-10 v-if="item.child && item.open" v-for="(ele, i) in item.child" :key="i">
          <li>
            <p text-16 :class="{ 'text-12!': locale == 'en' }" fw pb-10 v-if="ele.path">
              <NuxtLink :to="localePath(ele.path)" external>{{ ele.title }}</NuxtLink>
            </p>
            <p text-16 :class="{ 'text-12!': locale == 'en' }" fw pb-10 v-else>{{ ele.title }}</p>
            <ul f-c flex-wrap>
              <li w='25%' mb-15 flex-shrink-0 text-14 :class="{ 'text-10!': locale == 'en' }"
                v-for="(nav, n) in ele.child">
                <NuxtLink :to="localePath(nav.path)" external>{{ nav.title }}</NuxtLink>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <div h-60 fixed bottom-0 left-0 w-full f-c-b px-15 class="nav_shadow" bg-white>
      <a-dropdown>
                <span  @click="loginBtn">{{ $t('login') }}</span>
                <template #overlay>
                  <a-menu>
                    <a-menu-item>
                      <span  @click="Logout">{{ $t('logout') }}</span>
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
      <a-dropdown>
        <a href="javascript:;" @click.prevent f-c-c cp>
          {{ locale === 'en' ? 'En' : locale === 'hk' ? '繁' : '简' }} <span i-carbon-caret-down block w-16 h-16></span>
        </a>
        <template #overlay>
          <a-menu>
            <a-menu-item>
              <NuxtLink :to="switchLocalePath('cn')">简</NuxtLink>
            </a-menu-item>
            <a-menu-item>
              <NuxtLink :to="switchLocalePath('hk')">繁</NuxtLink>
            </a-menu-item>
            <!-- <a-menu-item>
              <NuxtLink :to="switchLocalePath('en')">En</NuxtLink>
            </a-menu-item> -->
          </a-menu>
        </template>
      </a-dropdown>
      <!-- <p text-14 :class="{ 'text-10!': locale == 'en' }">
        <NuxtLink :to="localePath('/contact')" external>{{ $t('contact_us') }}</NuxtLink>
      </p> -->
      <!-- <p text-14 :class="{ 'text-10!': locale == 'en' }">
        <NuxtLink :to="localePath('/download')" external>{{ $t('login') }}</NuxtLink>
      </p> -->
      <!-- <p text-14 :class="{ 'text-10!': locale == 'en' }">
        <NuxtLink :to="localePath('/download')" external>{{ $t('open_account') }}</NuxtLink>
      </p> -->
      <!-- <button btn-accent flex-shrink-0>
        <NuxtLink :class="{ 'text-10!': locale == 'en' }" :to="localePath('/download')" external>
          {{ $t('download_center') }}
        </NuxtLink>
      </button> -->
    </div>
  </div>
</template>
<style>
.nav_shadow {
  box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.3);
}
</style>
