<script setup>
import useGetCode from '@/assets/hooks/useGetCode'
import storage from '@/assets/storage';
const props = defineProps(['open'])
const isregion = ref(false);
const region = ref('CN'),mobile = ref(''),captcha = ref('')
const showRegion = ref('86')
const isloading = ref(false),login_loading = ref(false)
const { smsBtnText, resume, isActive } = useGetCode(60)
const onFinish = values => {
  console.log('Success:', values);
};
const onFinishFailed = errorInfo => {
  console.log('Failed:', errorInfo);
};
const user = useUserStore()
function handleOk() {
    user.setAlertLogin(false)
}
function hadleChange(item) {
  region.value = item.code
  showRegion.value = item.no
  isregion.value = false
}
function regionFn() {
  isregion.value = !isregion.value
}
async function send_sms() {
    if(!mobile.value) return
    if(isloading.value || isActive.value) return
    isloading.value = true
    const data = await sendSms({
        region:region.value,
        mobile:mobile.value
    })
    if(data.status==0) {
        resume()
    } else {
        console.log(data.info)
    }
    isloading.value = false
}
async function verify_sms() {
    if(!mobile.value||!captcha.value) return
    if(login_loading.value) return
    login_loading.value = true
    const data = await verifySms({
        region:region.value,
        mobile:mobile.value,
        captcha:captcha.value
    })
    if(data.status==0){
        try{
            storage.setHasLogin(true)
            storage.setUserInfo(data.data.list[data.data.index])
            storage.setToken(data.data.list[data.data.index].token)
            storage.setUserList(data.data.list)
            user.login(data.data)
            handleOk()
        }catch(e){
            console.log(e)
        }
        console.log(data,'登录数据')
    }else{
        console.log(data.info)
    }
    login_loading.value = false
}
</script>

<template>
    <div text-gray:80>
        <a-modal v-model:open="props.open" title="登录" @cancel="handleOk" :footer="false" >
            <section py-20>
                <a-form  name="basic"  :label-col="{ span: 6 }" :wrapper-col="{ span: 15 }"
                    autocomplete="off" @finish="onFinish" @finishFailed="onFinishFailed">
                    <a-form-item  name="username" label="手机号"  relative>
                        <div absolute left-10 abs_t titm text-14px z-1 f-c cp @click="regionFn">
                            +{{ showRegion }}
                            <span i-material-symbols-keyboard-arrow-down text-18 mt-2></span>
                        </div>
                        <region-select relative top-40 v-show="isregion" @change="hadleChange"></region-select>
                        <a-input pl-70 v-model:value="mobile" placeholder="请输入手机号"/>
                    </a-form-item>

                    <a-form-item  name="username"  label="验证码" relative>
                        <div  absolute right-0 abs_t z-1>
                            <a-button type="primary" :loading="isloading"  @click="send_sms()">{{smsBtnText}}</a-button>
                        </div>
                        <a-input v-model:value="captcha" placeholder="请输入验证码"/>
                    </a-form-item>

                    <a-form-item :wrapper-col="{ offset: 6, span: 15 }">
                        <a-button :loading="login_loading" type="primary" w-full @click="verify_sms">登录</a-button>
                    </a-form-item>
                </a-form>
            </section>
        </a-modal>
    </div>
</template>
