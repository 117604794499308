<script setup>

</script>
<template>
  <div bg-body text-white pb-22 pt-39 xl:f-c lt-xl:(pt-30 pb-26)>
    <div mwc>
      <div items-center xl:f-c-b>
        <div pt-26>
          <ul ml-100 f-c lt-xl:(f-c-b mb-20)>
            <li text-16 mr-80 lt-xl:(text-13 mr-0)>
              <div flex flex-col h-150 justify-between py-5>
                <p l-n>{{ $t('qgfwrx') }}</p>
                <p l-n>400-100-1359</p>
                <div l-n>
                  (852)2957-6133(香港）
                <p mt-6 class="content-tips" text-12>周一至周五9：00~18：00</p>
                </div>
              </div>
            </li>
            <li text-16 mr-80 lt-xl:(text-13 mr-0)>
              <div flex flex-col h-150 justify-between py-5>
                <p l-n>{{ $t('dhxd') }}</p>
                <p l-n>400-100-1359  <p mt-6 class="content-tips" text-12>{{ $t('jthxa1za1')  }}</p></p>
                <div l-n>
                  (852)2957-6018(香港）
                <p mt-6 class="content-tips" text-12>周一至周五8：00~00：00</p>
                </div>
              </div>
            </li>
            <li text-16 mr-80 lt-xl:(text-13 mr-0)>
              <div flex flex-col h-150 justify-between  py-5>
                <!-- <p l-n>{{ $t('dhxd') }}</p> -->
                <p l-n>{{ $t('dy1')  }} <p mt-6 >cs@forthright-sec.com</p></p>
                <div l-n>
                  {{ $t('dz1')  }}
                <p mt-6>{{ $t('dznr')}}</p>
                <p mt-6></p>

                </div>
              </div>
            </li>
            <li text-16 mr-80 lt-xl:(text-13 mr-0)>
              <div flex flex-col h-150 justify-between py-5>
                <p l-n>{{ $t('tksm') }}</p>
                <NuxtLink :to="localePath('/service_detail?pid=159&id=607')" external>
                  <p l-n >{{ $t('mzsmjfxpl') }}</p>
                </NuxtLink>
                <NuxtLink :to="localePath('/service_detail?pid=159&id=606')" external>
                <p l-n> {{ $t('gryszc') }}</p>
              </NuxtLink>
              </div>
            </li>

          </ul>
        </div>
      </div>
      <div ml-100 mt-16 text-14 text-justify text-white text-opacity-39 lt-xl:text-12>
        {{ $t('bwzjsnr') }}
      </div>
      <div mt-16 text-14 text-center text-white text-opacity-39 lt-xl:text-12>  {{ $t('bqsy') }}</div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.content-tips {

  font-family: PingFangSC, PingFang SC;
  font-weight: 400;

  color: #FFFFFF;


  font-style: normal;

}
.mark-red {
  display: inline-block;
  width: 16px;
  height: 16px;
background: #C52727;
}
</style>
