export default [
  {
    title: "首页",
    name: "index",
    path: "/",
  },
  {
    title: "产品介绍",
    path: "",
    open: true,
    name: "stock",
    child: [
      {
        title: "证券交易",
        path: "/stock_trade",

      },
      {
        title: "期货交易",
        path: "/futures_trade",

      },
      {
        title: "基金交易",
        path: "/fund",

      },


    ],
  },
  {
    title: "财富服务",
    name: "wealth",
    child: [

      {
        title: "私人财富",
        path: "/wealth_planning",
        // child: [
        //   {
        //     title: "全权委托",
        //     path: "/full_delegation",
        //   },
        // ],
      },

    ],
  },
  {
    title: "合作伙伴",
    name: "global_investment",
    path: "/global_investment",

  },
  // {
  //   title: "投资见解",
  //   name: "investment",
  //   child: [
  //     {
  //       title: "全部内容",
  //       path: "/investment?id=1",
  //       child: [
  //         {
  //           title: "市场解读",
  //           path: "/investment?id=2",
  //         },
  //         {
  //           title: "个人理财",
  //           path: "/investment?id=3",
  //         },
  //         {
  //           title: "IPO",
  //           path: "/investment?id=4",
  //         },
  //         {
  //           title: "研究报告",
  //           path: "/investment?id=5",
  //         },
  //         {
  //           title: "关于方德",
  //           path: "/investment?id=6",
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   title: "活动",
  //   path: "/activity",
  //   name: "activity",
  // },
  {
    title: "关于我们",
    path: "/company",
    name: "company",
  },
  {
    title: "下载",
    path: "/download",
    name: "download",
  },
  {
    title: "帮助中心",
    path: "/service",
    name: "service",
  }
];
