export default [
  {
    title: "首頁",
    name: "index",
    path: "/",
  },
  {
    title: "產品介紹",
    path: "",
    open: true,
    name: "stock",
    child: [
      {
        title: "證券交易",
        path: "/stock_trade",

      },
      {
        title: "期貨交易",
        path: "/futures_trade",

      },
      {
        title: "基金交易",
        path: "/fund",

      },


    ],
  },
  {
    title: "財富服務",
    name: "wealth",
    child: [

      {
        title: "私人財富",
        path: "/wealth_planning",
        // child: [
        //   {
        //     title: "全權委托",
        //     path: "/full_delegation",
        //   },
        // ],
      },

    ],
  },
  {
    title: "合作伙伴",
    name: "global_investment",
    path: "/global_investment",


  },
  // {
  //   title: "投資見解",
  //   name: "investment",
  //   child: [
  //     {
  //       title: "全部內容",
  //       path: "/investment?id=1",
  //       child: [
  //         {
  //           title: "市場解讀",
  //           path: "/investment?id=2",
  //         },
  //         {
  //           title: "個人理財",
  //           path: "/investment?id=3",
  //         },
  //         {
  //           title: "IPO",
  //           path: "/investment?id=4",
  //         },
  //         {
  //           title: "研究報告",
  //           path: "/investment?id=5",
  //         },
  //         {
  //           title: "關於方德",
  //           path: "/investment?id=6",
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   title: "活動",
  //   path: "/activity",
  //   name: "activity",
  // },
  {
    title: "關於我們",
    path: "/company",
    name: "company",
  },
  {
    title: "下載",
    path: "/download",
    name: "download",
  },
  {
    title: "幫助中心",
    path: "/service",
    name: "service",
  }
];
