export default [
  {
    title: "Home",
    name: "index",
    path: "/",
  },
  {
    title: "Products",
    path: "",
    open: true,
    name: "stock",
    child: [
      {
        title: "securities trading",
        path: "/stock_trade",
        child: [
          {
            title: "U.S. stocks",
            path: "/stock?id=1",
          },
          {
            title: "Hong Kong stock",
            path: "/stock?id=2",
          },
          {
            title: "Shanghai-Hong Kong Stock Connect",
            path: "/stock?id=3",
          },
          {
            title: "Warrant of share",
            path: "/stock?id=4",
          },
          {
            title: "CBBC",
            path: "/stock?id=5",
          },
          {
            title: "IPO",
            path: "/stock?id=6",
          },
        ],
      },
      {
        title: "Funds",
        path: "/fund",
        child: [
          {
            title: "Fund List",
            path: "/fund_product?id=1",
          },
          {
            title: "Fund scheduled investment",
            path: "/fund_product?id=2",
          },
          {
            title: "Fund portfolio",
            path: "/fund_product?id=3",
          },
          {
            title: "Private equity",
            path: "/fund_product?id=4",
          },
        ],
      },
      {
        title: "Futures trading",
        path: "/futures_trade",
        child: [
          {
            title: "Global Futures",
            path: "/futures?id=1",
          },
          {
            title: "Hong Kong Futures",
            path: "/futures?id=2",
          },
        ],
      },
      {
        title: "Hong Kong Insurance",
        path: "/insurance",
        child: [
          {
            title: "Savings insurance",
            path: "/insurance_detail?id=1",
          },
          {
            title: "Critical illness protection",
            path: "/insurance_detail?id=2",
          },
          {
            title: "Life insurance",
            path: "/insurance_detail?id=3",
          },
          {
            title: "Endowment annuity",
            path: "/insurance_detail?id=4",
          },
          {
            title: "Medical insurance",
            path: "/insurance_detail?id=5",
          },
          {
            title: "MPF",
            path: "/insurance_detail?id=6",
          },
        ],
      },
    ],
  },
  {
    title: "Wealth Services",
    name: "wealth",
    child: [
      {
        title: "Wealth Planning",
        path: "/wealth_planning",
        child: [
          {
            title: "Carte Blanche",
            path: "/full_delegation",
          },
        ],
      },
    ],
  },
  // {
  //   title: "Investment Insights",
  //   name: "investment",
  //   child: [
  //     {
  //       title: "All content",
  //       path: "/investment?id=1",
  //       child: [
  //         {
  //           title: "Market Interpretation",
  //           path: "/investment?id=2",
  //         },
  //         {
  //           title: "Personal Finance",
  //           path: "/investment?id=3",
  //         },
  //         {
  //           title: "IPO",
  //           path: "/investment?id=4",
  //         },
  //         {
  //           title: "Research Report",
  //           path: "/investment?id=5",
  //         },
  //         {
  //           title: "About Fonde",
  //           path: "/investment?id=6",
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   title: "Activities",
  //   path: "/activity",
  //   name: "activity",
  // },
  {
    title: "About Us",
    path: "https://home.forthright-sec.com/en/about/",
    name: "about",
    target: '_blank'
  },
  {
    title: "Download",
    path: "/download",
    name: "download",
  },
  {
    title: "Help Center",
    path: "/service",
    name: "service",
  },
];
