<script setup>
const { locale } = useI18n()
const user = useUserStore()
const isIp = ref(true)
const getIp = async ()  => {
  const data =  await getIP({})
  isIp.value = data.data.Country === '中国' ? true : false
  console.log(isIp.value)
}
getIp()
const isPC = ref(useDeviceType().value=='pc')
console.log(isPC.value)

</script>

<template>
  <main f-col bg-white text-18 text-body min-h-screen :class="[locale=='en'?'font-din':'']">
    <Header :isIp="isIp" />
    <div  flex-1 h-full>
      <a-config-provider
          :theme="{
            token: {
              colorPrimary: '#AA7322',
            },
          }"
        >
        <slot/>
    </a-config-provider>

    </div>
    <Footer v-if="isPC" />
    <MobileFooter v-if="!isPC" />

    <Login :open="user.alertLogin"/>
  </main>
</template>
